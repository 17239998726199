import React from 'react';
import { QBox, QSelect, QStack } from '@qualio/ui-components';

type TemplateTableStatusFilterProps = {
  templateStatus: string;
  setTemplateStatus: any;
};

const TemplateTableStatusFilter: React.FC<TemplateTableStatusFilterProps> = ({ templateStatus, setTemplateStatus }) => {
  return (
    <QStack direction={['row']} spacing="6" mb="6">
      <QBox w="30%">
        <div data-cy="templateStatusDropdown">
          <QSelect
            isLoading={false}
            isSearchable={false}
            onChange={(e) => {
              if (!e) {
                return;
              }
              setTemplateStatus(e.value);
            }}
            options={[
              {
                label: 'Effective',
                value: 'effective',
              },
              {
                label: 'Archived',
                value: 'archived',
              },
            ]}
            value={templateStatus}
            size="sm"
            isDisabled={false}
            data-cy="selectStatus"
          />
        </div>
      </QBox>
      <QBox w="70%" />
    </QStack>
  );
};

export default TemplateTableStatusFilter;
