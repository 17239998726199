import { Editor } from '@ckeditor/ckeditor5-core';
import { Marker, Position, Range } from '@ckeditor/ckeditor5-engine';

export const getMarkerAtPosition = (
  editor: Editor,
  position: Position,
): any => {
  for (const marker of editor.model.markers) {
    const markerRange = marker.getRange();

    if (isPositionInRangeBoundaries(markerRange, position)) {
      if (marker.name.startsWith('lockedSectionHeaders:')) {
        return marker;
      }
    }
  }
};

export const getMarkersInRange = (
  editor: Editor,
  range: Range | null,
): Array<Marker> => {
  const markers = [];
  for (const marker of editor.model.markers) {
    const markerRange = marker.getRange();

    if (
      isRangeConnected(range, markerRange) &&
      marker.name.startsWith('lockedSectionHeaders:')
    ) {
      markers.push(marker);
    }
  }
  return markers;
};

const isRangeConnected = (range: Range | null, otherRange: Range | null) => {
  if (!range || !otherRange) {
    return false;
  }
  return (
    range.isIntersecting(otherRange) ||
    range.start.isEqual(otherRange.end) ||
    range.end.isEqual(otherRange.start)
  );
};

export const isPositionInRangeBoundaries = (
  range: Range,
  position: Position,
): boolean =>
  range.containsPosition(position) ||
  range.end.isEqual(position) ||
  range.start.isEqual(position);

export const updateEditorSelectionToEditableRegion = (editor: Editor): void => {
  editor.model.change((writer) => {
    const range = writer.createPositionAt(
      new Position(editor.model.document.getRoot() as any, [1, 0]),
    );
    writer.setSelection(range);
  });
};
