import {
  QButton,
  QCheckbox,
  QCloseButton,
  deprecated,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import React, { useState } from 'react';
import draftUpgradeApi from '../../../services/draftUpgradeApi';
import { UpgradableDraft, UpgradeDraftResponse, UpgradeRequest } from '../../../types';

type UpdateDraftsModalProps = {
  drafts: UpgradableDraft[];
  companyId: number;
  templateId: number;
  version: number;
  isOpen: boolean;
  onClose: any;
  onSubmit: any;
};

const UpdateDraftsModal: React.FC<UpdateDraftsModalProps> = (props) => {
  const intial_array: number[] = [];
  const [checkedDraftIds, setCheckedDraftIds] = useState(intial_array);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const { showToast } = useToastProvider();

  const allChecked = checkedDraftIds.length === props.drafts.length;

  const toggleDraft = (toggledId: number, checked: boolean) => {
    if (checked) {
      setCheckedDraftIds((prevChecked) => prevChecked.concat(toggledId));
      setSubmitEnabled(true);
    } else {
      if (checkedDraftIds.length === 1) {
        setSubmitEnabled(false);
      }
      setCheckedDraftIds((prevChecked) => prevChecked.filter((draftId) => draftId !== toggledId));
    }
  };

  const toggleAll = (checked: boolean) => {
    if (checked) {
      setCheckedDraftIds(props.drafts.map((d) => d.id));
      setSubmitEnabled(true);
    } else {
      setCheckedDraftIds([]);
      setSubmitEnabled(false);
    }
  };

  const formRow = (draft: UpgradableDraft) => {
    return {
      check: (
        <QCheckbox
          key={draft.id}
          isChecked={checkedDraftIds.includes(draft.id)}
          onChange={(e) => toggleDraft(draft.id, e.target.checked)}
        />
      ),
      id: draft.code,
      title: draft.title,
      templateVersion: draft.version,
    };
  };

  const submitDraftUpgrade = () => {
    const request: UpgradeRequest = {
      target_template_id: props.templateId,
      documents_ids: checkedDraftIds,
      company_id: props.companyId,
    };
    setSubmitEnabled(false);
    draftUpgradeApi
      .upgradeDrafts(request)
      .then((value: UpgradeDraftResponse) => {
        console.log(`document ids updated: ${value.updated_documents_ids}`);
        showToast({
          title: 'Success',
          description: 'Document drafts successfully updated',
          status: 'success',
        });
        props.onSubmit();
      })
      .catch((error) => {
        console.error(error);
        showToast({
          title: 'Error',
          description: determineToastErrorMessage(error),
          status: 'error',
        });
        setSubmitEnabled(true);
      });
  };

  const determineToastErrorMessage = (error: any): string => {
    // extract informational MTBE message and use it for toast message, if it is of certain format
    const errMsg = parseMessageFromMTBEError(error);
    if (errMsg && errMsg.includes('currently being edited')) {
      return errMsg;
    }
    // default message if unknown error format encountered
    return 'Failed to update drafts';
  };

  const parseMessageFromMTBEError = (error: any): string | null => {
    const msg = error?.response?.data?.message;
    if (msg) {
      return msg as string;
    }
    return null;
  };

  return (
    <QModal isOpen={props.isOpen} onClose={props.onClose} size="4xl">
      <QModalHeader>
        <QText>Update drafts with template version {props.version}</QText>
        <QCloseButton onClick={props.onClose} />
      </QModalHeader>
      <QModalBody>
        <deprecated.QDataTable
          columns={[
            {
              Header: <QCheckbox isChecked={allChecked} onChange={(e) => toggleAll(e.target.checked)} />,
              accessor: 'check',
              disableSortBy: true,
              width: '10%',
            },
            {
              Header: 'Id',
              accessor: 'id',
              width: '10%',
            },
            {
              Header: 'Title',
              accessor: 'title',
              width: '60%',
            },
            {
              Header: 'Template version',
              accessor: 'templateVersion',
              width: '20%',
            },
          ]}
          data={props.drafts.map(formRow)}
        />
      </QModalBody>
      <QModalActions>
        <QButton onClick={props.onClose} variant="outline">
          Cancel
        </QButton>
        <QButton isDisabled={!submitEnabled} onClick={submitDraftUpgrade}>
          Update drafts
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default UpdateDraftsModal;
