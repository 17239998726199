import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { environment } from '../environments/environment';
import mockData from './mockData.json';
import { mockCurrentUser } from '../__mocks__/mockCurrentUser';

if (process.env.NODE_ENV !== 'production' && environment.environmentName === 'development') {
  const mock = new MockAdapter(axios, { delayResponse: 500 });
  // Mock auth request
  mock.onPost(`https://auth-exchange.staging.qualio.com/company/${mockCurrentUser.companyId}/auth`).reply(200);

  mock
    .onGet(
      `https://api.staging.beta.qualio.com/v2/${mockCurrentUser.companyId}/users?company_id=${mockCurrentUser.companyId}`,
    )
    .reply(200, mockData.user);

  mock
    .onGet(`https://api.staging.beta.qualio.com/document_templates?company_id=${mockCurrentUser.companyId}`)
    .reply(200, mockData.template);

  mockData.template.forEach((template: any) => {
    mock
      .onGet(
        `https://api.staging.beta.qualio.com/v2/${mockCurrentUser.companyId}/template/${template.id}/next_document_code`,
      )
      .reply(200, `${template.document_id_prefix}${template.id}`);
  });

  mock
    .onGet(`https://api.staging.beta.qualio.com/tags?company_id=${mockCurrentUser.companyId}`)
    .reply(200, mockData.tags);

  // Let everything else through
  mock.onAny().passThrough();
}

export const apiClient = axios.create({
  baseURL: environment.API_URL,
  withCredentials: true,
});

export const templateApiClient = axios.create({
  baseURL: environment.API_URL,
  withCredentials: true,
});

templateApiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = environment.loginUrl;
    }
    return error;
  },
);

export const medtechApiClient = axios.create({
  baseURL: environment.MEDTECH_API,
  withCredentials: true,
});

export const fetcher = (url: string) => apiClient.get(url).then((res: any) => res.data);

export const authenticate = async (companyID: number | string) =>
  apiClient.post(`${environment.AUTH_API_URL}/company/${companyID}/auth`);
