export const environment = {
  production: false,
  MEDTECH_API: 'https://api.staging.beta.qualio.com',
  API_URL: 'https://api.template-microservice.staging.beta.qualio.com',
  AUTH_API_URL: 'https://auth-exchange.staging.qualio.com',
  APP_URL: 'https://app.document-templates.staging.qualio.com',
  environmentName: 'staging',
  loginUrl: 'https://app.staging.beta.qualio.com/login',
  authSuccessRefreshIntervalMillis: 60000,
  authErrorRetryIntervalMillis: 5000,
  LOG_LEVEL: 'debug',
  redirect_to_login_on_jwt_failure: true,
  launchDarklyClientKey: '61e6a31060139c1610d1516b',
  GLOBAL_SEARCH_SERVICE_URL: 'https://global-search.staging.beta.qualio.com',
  QRI_SERVICE_URL: 'https://qri.app.staging.beta.qualio.com',
};
