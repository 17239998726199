import { convertTimestampToDate } from '../utils/dateUtils';

export type ChangeControl = {
  owner_id: string;
  sections: CCSection[];
  company_id: string;
  tags: string[];
};

export type CCSection = {
  title: string;
  position: number;
  content: string;
  type: string;
};

export interface CreatedChangeControlBackend extends ChangeControl {
  id: string;
  last_modified_time: number;
  created_time: number;
  opened_time: number;
  closed_time: number | null;
  last_audit_significant_change_at: number;
  change_control_reference: string;
  template_id: number;
  template_uuid: string;
  owner?: ChangeControlUser; // this ? can be removed once this pr is merged https://github.com/Everworks/medtech-backend/pull/2030
}

export type ChangeControlUser = {
  id: number;
  email: string;
  full_name: string;
  staff: boolean;
};

export class CreatedChangeControl {
  owner_id: string;
  sections: CCSection[];
  company_id: string;
  tags: string[];
  id: string;
  last_modified_time: Date;
  created_time: Date;
  opened_time: Date;
  closed_time!: Date;
  last_audit_significant_change_at: Date;
  change_control_reference: string;
  template_id: number;
  template_uuid: string;
  owner?: ChangeControlUser; // same as above

  constructor(cc_backend: CreatedChangeControlBackend) {
    this.owner_id = cc_backend.owner_id;
    this.sections = cc_backend.sections;
    this.company_id = cc_backend.company_id;
    this.tags = cc_backend.tags;
    this.id = cc_backend.id;
    this.last_modified_time = convertTimestampToDate(cc_backend.last_modified_time);
    this.created_time = convertTimestampToDate(cc_backend.created_time);
    this.opened_time = convertTimestampToDate(cc_backend.opened_time);
    this.last_audit_significant_change_at = convertTimestampToDate(cc_backend.last_audit_significant_change_at);
    this.last_modified_time = convertTimestampToDate(cc_backend.last_modified_time);
    this.change_control_reference = cc_backend.change_control_reference;
    this.template_id = cc_backend.template_id;
    this.template_uuid = cc_backend.template_uuid;
    if (cc_backend.closed_time != null) {
      this.closed_time = convertTimestampToDate(cc_backend.closed_time);
    }
    this.owner = cc_backend.owner;
  }
}
