import { QAlert, QText, QBox, QButton, QSpinner } from '@qualio/ui-components';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import draftUpgradeApi from '../../../services/draftUpgradeApi';
import UpdateDraftsModal from '../Modal/UpdateDraftsModal';

type OldDraftsAlertProps = {
  companyId: number;
  templateId: string;
  version: number;
};

const OldDraftsAlert: React.FC<OldDraftsAlertProps> = (props) => {
  const [upgradeLoadId, setUpgradeLoadId] = useState(nanoid());
  const { data, isLoading, error } = useQuery(['upgradableDrafts', props.templateId, upgradeLoadId], () =>
    draftUpgradeApi.getUpgradableDrafts(props.companyId, props.templateId),
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const closeModal = () => setModalIsOpen(false);
  const openModal = () => setModalIsOpen(true);

  const onUpgrade = () => {
    closeModal();
    setUpgradeLoadId(nanoid());
  };

  if (error) {
    return <QAlert description="Something went wrong while fetching drafts for this template" status="error" />;
  }
  if (isLoading) {
    return <QSpinner size="md" />;
  }

  if (data && data.total > 0) {
    return (
      <QBox pb="4">
        <QAlert description={formDescription(data.total, openModal)} status="warning" title="" />
        <UpdateDraftsModal
          drafts={data.items}
          companyId={props.companyId}
          templateId={Number(props.templateId)}
          version={props.version}
          isOpen={modalIsOpen}
          onClose={closeModal}
          onSubmit={onUpgrade}
        />
      </QBox>
    );
  }
  return <></>;
};

function formDescription(numberOfDrafts: number, openModal: any) {
  const draftWord = numberOfDrafts > 1 ? 'drafts' : 'draft';
  const narrative =
    numberOfDrafts > 1 ? 'are using older versions of this template' : 'is using an older version of this template';
  return (
    <QText mt="1">
      <QButton variant="link" size="xs" onClick={openModal}>
        {numberOfDrafts} {draftWord}
      </QButton>{' '}
      {narrative}
    </QText>
  );
}

export default OldDraftsAlert;
