import React from 'react';
import { QBox, QRadio, QRadioGroup, QSelect, QText } from '@qualio/ui-components';
import { Operations, Resolution } from '../../../../types/resolution';
import { QSelectItem } from '@qualio/ui-components/lib/QAtoms/Select/types';

export type SectionResolutionModalBodyProps = {
  resolutions: Resolution[];
  updateOperation: (operation: Operations, index: number, resolutionsInput: Resolution[]) => void;
  updateRecipientSectionIndex: (recipientSectionIndex: number, index: number, resolutionsInput: Resolution[]) => void;
  selectOptions: QSelectItem[];
};

const SectionResolutionModalBody = (props: SectionResolutionModalBodyProps) => {
  return (
    <QBox mt="4">
      {props.resolutions.map((resolution, index) => (
        <QBox mt="2" key={index}>
          <QText>Section '{resolution.section_title}' is marked to be removed, please select a resolution</QText>
          <QBox mt="4" display={'flex'} flexDirection="row" justifyContent="space-between" alignItems="baseline">
            <QRadioGroup
              onChange={(val) => {
                props.updateOperation(Operations[val as unknown as Operations], index, props.resolutions);
              }}
            >
              <QRadio value={'prepend'} data-cy={'prependRadio' + index}>
                Insert before
              </QRadio>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <QRadio value={'append'} data-cy={'appendRadio' + index}>
                Insert after
              </QRadio>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <QRadio value={'discard'} data-cy={'discardRadio' + index}>
                Discard
              </QRadio>
            </QRadioGroup>
            {props.resolutions[index].operation !== Operations.discard && (
              <>
                <QText>In section:&nbsp;</QText>
                <QBox width="25%">
                  <div data-cy={'selectSection' + index}>
                    <QSelect
                      isLoading={false}
                      isSearchable={false}
                      isDisabled={false}
                      value={props.resolutions[index].recipient_section_index.toString()}
                      onChange={(e) => {
                        if (!e) {
                          return;
                        }
                        props.updateRecipientSectionIndex(Number(e.value), index, props.resolutions);
                      }}
                      options={props.selectOptions}
                    />
                  </div>
                </QBox>
              </>
            )}
          </QBox>
        </QBox>
      ))}
    </QBox>
  );
};

export default SectionResolutionModalBody;
