import { CKEditorInstance } from '../../types/CKEditorInstance';

const commandsToTrack = [
  {
    commandName: 'codeBlock',
    trackEventTitle: 'Template Editor | Code block toggled',
  },
  {
    commandName: 'todoList',
    trackEventTitle: 'Template Editor | Checkbox toggled',
  },
  {
    commandName: 'QualioSectionListCommand',
    trackEventTitle: 'Template Editor | Section List Toggled',
  },
  {
    commandName: 'mention',
    trackEventTitle: 'Template Editor | Smart Link Created',
  },
  {
    commandName: 'insertTable',
    trackEventTitle: 'Template Editor | Table Created',
  },
];

export const configureAnalytics = (
  editor: CKEditorInstance,
  analytics: SegmentAnalytics.AnalyticsJS,
) => {
  for (const trackable of commandsToTrack) {
    editor.commands.get(trackable.commandName)?.on('execute', () => {
      analytics.track(trackable.trackEventTitle);
    });
  }
};
