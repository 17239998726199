import { DecoupledEditor } from '@ckeditor/ckeditor5-editor-decoupled';
import * as CKE from '@ckeditor/ckeditor5-react';
import React from 'react';
import { CKEditorConfig } from '../shared/CKEditor/config/CKEditorConfig';
import { getCKEditorConfig } from '../shared/CKEditor/config/EditorConfig';
import { QualioSectionedContent } from '../shared/CKEditor/models';
import { QualioAttachmentCKUploadAdapter } from '../shared/CKEditor/plugins/Attachment/QualioAttachmentCKUploadAdapter';
import { updateEditorSelectionToEditableRegion } from '../shared/CKEditor/plugins/LockSectionHeaders/utils';
import { CKEditorInstance } from '../shared/CKEditor/types/CKEditorInstance';
import { configureAnalytics } from '../shared/CKEditor/util/Analytics/AnaylyticsUtils';
import { PresentationUtils } from '../shared/CKEditor/util/PresentationUtils';
import { ToolbarManager, useToolbarManager } from '../shared/CKEditor/util/ToolbarManager';
import styles from './SectionedContentEditor.module.css';
import { PasteFromOfficeEnhanced } from '@ckeditor/ckeditor5-paste-from-office-enhanced';
import { useFlags } from 'launchdarkly-react-client-sdk';

const configureUploadAdapter = (editor: CKEditorInstance) => {
  const fileRepositoryPlugin = editor.plugins.get('FileRepository');
  fileRepositoryPlugin.createUploadAdapter = (loader: any) => {
    return new QualioAttachmentCKUploadAdapter(loader);
  };
};

export const setupClickListener = (editor: CKEditorInstance): void => {
  editor.editing.view.listenTo(editor.editing.view.document, 'click', (evt: any, data: any) => {
    const clickedViewElement = data.target;
    const href = clickedViewElement.getAttribute('href');
    // always open smart links in new tab
    if (clickedViewElement.getAttribute('data-mention') && href) {
      window.open(href, '_blank');
      return;
    }
  });
};

function mathCharactersExtended(editor: CKEditorInstance): void {
  const scp = editor.plugins.get('SpecialCharacters');
  scp.addItems('Mathematical', [{ title: 'delta', character: 'Δ' }]);
}

const createEditorReadyHandler =
  (toolbarManager: ToolbarManager, isSectionedContent: boolean) =>
  (editor: CKEditorInstance): void => {
    editor.focus();
    if (isSectionedContent) {
      updateEditorSelectionToEditableRegion(editor);
    }
    setupClickListener(editor);
    mathCharactersExtended(editor);
    toolbarManager.setToolbarFromEditor(editor);
    configureUploadAdapter(editor);
    editor.model.schema.setAttributeProperties('htmlAttributes', {
      isFormatting: true,
    });
    configureAnalytics(editor, analytics);
  };

export const SectionedContentEditor: React.FC<{
  data: QualioSectionedContent;
  companyId: number;
  handleChange: (editor: CKEditorInstance) => Promise<void>;
  isSectionedContent?: boolean;
  pasteFromOfficeEnhanced?: boolean;
}> = ({ data, companyId, handleChange, isSectionedContent = true, pasteFromOfficeEnhanced = false }) => {
  const { smartlinkEverything, multilevelListEnabled } = useFlags();

  const editorConfig: CKEditorConfig = {
    ...getCKEditorConfig(companyId, handleChange, smartlinkEverything, multilevelListEnabled),
  };
  if (pasteFromOfficeEnhanced) {
    editorConfig.plugins?.push(PasteFromOfficeEnhanced as any);
  }
  const toolbarManager = useToolbarManager();

  return (
    <>
      <div className={styles['sections-editor__heading-section']} id="pageHeaderSection" />
      <div id="sectionEditorContent" className={`${styles['sections-editor__document__section-content']} document`}>
        <CKE.CKEditor
          onReady={createEditorReadyHandler(toolbarManager, isSectionedContent)}
          editor={DecoupledEditor}
          watchdogConfig={{ saveInterval: 30000 }}
          data={
            isSectionedContent
              ? PresentationUtils.determineEditorContentFromSectionsData(data)
              : data.sections[0].content
          }
          config={editorConfig}
        />
      </div>
    </>
  );
};
