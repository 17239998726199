import Element from '@ckeditor/ckeditor5-engine/src/model/element';
import Writer from '@ckeditor/ckeditor5-engine/src/model/writer';

export const autoParagraphEmptyContent = (writer: Writer) => {
  const { document } = writer.model;
  const root = document.getRoot('main');
  let hasChangedContent = false;

  if (root) {
    // check if there are two section headings without any content in between
    for (const child of root.getChildren()) {
      const element = child as Element;
      if (
        element.name === 'sectionHeading' &&
        ((element.nextSibling &&
          (element.nextSibling as Element).name === 'sectionHeading') ||
          !element.nextSibling)
      ) {
        writer.insert(writer.createElement('paragraph'), element, 'after');
        hasChangedContent = true;
      }
    }
  }

  return hasChangedContent;
};
