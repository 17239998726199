import React from 'react';
import { QStack, QTag, QText } from '@qualio/ui-components';

import { TemplateDetail } from '../../../../../types';
import { getTagColor, getTagText } from '../../../../../utils/textUtil';

type TemplateMetadataProps = {
  templateDetail: TemplateDetail | undefined;
};

const TemplateMetadata: React.FC<TemplateMetadataProps> = ({ templateDetail }) => {
  return (
    <QStack direction={['column', 'row']} pt="2">
      <QText fontSize="sm">Status </QText>
      <QTag data-cy="template-status" variantColor={getTagColor(templateDetail?.status)}>
        {getTagText(templateDetail?.status)}
      </QTag>
      <QText fontSize="sm"> · </QText>
      <QText fontSize="sm">Version</QText>
      <QText data-cy="template-version" color="gray.800" fontSize="sm">
        {templateDetail?.version}
      </QText>
    </QStack>
  );
};

export default TemplateMetadata;
