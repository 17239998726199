export function extractErrorMessageForToast(error: any): string {
  // Used to extract certain errors from the templates backend for display to users
  if (error?.response?.status === 403 || error?.response?.status === 401) {
    const detailMsg = error.response.data?.detail;
    if (detailMsg) {
      return detailMsg;
    }
  }
  if (error?.response?.data?.detail === 'This is not the most current version of this template') {
    return 'This template has already been amended elsewhere, please refresh your browser and try again';
  }
  if (error?.response?.data?.body?.indexOf("Section title can't contain the following characters") > -1) {
    return error.response.data.body;
  }
  if (error?.response?.data?.body?.indexOf('Template prefix is already in use') > -1) {
    return error.response.data.body;
  }
  if (error?.response?.data?.body?.indexOf('Template title is already in use') > -1) {
    return error.response.data.body;
  }
  if (error?.response?.data?.body?.indexOf('Invalid attachment id') > -1) {
    return error.response.data.body;
  }

  return '';
}

export function formErrorMessageForToast(messagePrefix: string, error: any): string {
  const errorDetail = extractErrorMessageForToast(error);

  if (errorDetail === '') {
    return messagePrefix;
  }
  return messagePrefix + ': ' + errorDetail;
}
