import { QBox, QFormControl, QHeading, QInput, QStack } from '@qualio/ui-components';
import React, { useEffect } from 'react';
import { TemplateDTO } from '../../../types';
import { trimAndReplaceMultiSpaceWithOneSpace } from '../../../utils/textUtil';
import {
  CreateEditTemplatePage,
  CreateEditTemplatePageType,
} from '../../../views/CreateEditTemplateView/CreateEditTemplatePage';

export type IDAndTitleProps = {
  setTemplateDto: (templateDto: TemplateDTO) => void;
  templateDto: TemplateDTO;
  prefixError: string;
  setPrefixError: (error: string) => void;
  titleError: string;
  setTitleError: (error: string) => void;
  setPageError: (page: CreateEditTemplatePageType, value: boolean) => void;
  titleTouched: boolean;
  setTitleTouched: (value: boolean) => void;
  templateId: string | undefined;
};

const PrefixErrors: Record<string, string> = {
  duplicate: 'This ID prefix already exists or has been previously used. Please choose a different ID Prefix',
  required: 'ID prefix is required',
  pattern: 'ID prefix can contain letters and digits only',
};

const TitleErrors: Record<string, string> = {
  duplicate: 'This title already exists or has been previously used. Please choose a different title',
  required: 'Title is required',
};

const IDAndTitle = (props: IDAndTitleProps) => {
  const checkIdFormat = (id: string) => {
    const regex = new RegExp(/^[A-Za-z0-9]+-?$/);
    return regex.test(id);
  };

  useEffect(() => {
    document.getElementsByName('id')[0].focus();
  }, [props.templateId]);

  const validatePrefix = (prefixValue: string) => {
    if (prefixValue === '') {
      props.setPrefixError('required');
      props.setPageError(CreateEditTemplatePage.IDAndTitle, true);
    } else if (!checkIdFormat(prefixValue)) {
      props.setPrefixError('pattern');
      props.setPageError(CreateEditTemplatePage.IDAndTitle, true);
    }
  };

  const validateTitle = (titleValue: string) => {
    if (titleValue === '') {
      props.setTitleError('required');
      props.setPageError(CreateEditTemplatePage.IDAndTitle, true);
    }
  };

  return (
    <QBox className="stepper-content" pl="2" pr="2">
      <QStack direction={['column']} spacing="6">
        <QHeading size="md">ID and title</QHeading>
        <QFormControl
          label="ID prefix"
          helper="A prefix will be automatically added to every document number created with using this template. The prefix can contain letters and digits only."
          error={PrefixErrors[props.prefixError]}
          isInvalid={!!props.prefixError}
        >
          <QInput
            maxLength={75}
            onChange={(e) => {
              props.setPrefixError('');
              props.setTemplateDto({ ...props.templateDto, id: e.target.value });
              if (!props.titleError && props.titleTouched) {
                props.setPageError(CreateEditTemplatePage.IDAndTitle, false);
              }
            }}
            onBlur={(e) => {
              props.setTemplateDto({
                ...props.templateDto,
                id: e.target.value.trim(),
              });
              validatePrefix(e.target.value.trim());
            }}
            value={props.templateDto.id}
            name="id"
            isInvalid={!!props.prefixError}
            data-cy="template-id"
          />
        </QFormControl>
        <QFormControl label="Title" error={TitleErrors[props.titleError]} isInvalid={!!props.titleError}>
          <QInput
            onChange={(e) => {
              props.setTitleError('');
              props.setTemplateDto({ ...props.templateDto, title: e.target.value });
              if (!props.prefixError) {
                props.setPageError(CreateEditTemplatePage.IDAndTitle, false);
              }
            }}
            onBlur={(e) => {
              props.setTitleTouched(true);
              props.setTemplateDto({
                ...props.templateDto,
                title: trimAndReplaceMultiSpaceWithOneSpace(e.target.value),
              });
              validateTitle(e.target.value.trim());
            }}
            value={props.templateDto.title}
            isInvalid={!!props.titleError}
            name="title"
            data-cy="template-title"
          />
        </QFormControl>
      </QStack>
    </QBox>
  );
};

export default IDAndTitle;
