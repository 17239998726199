import { Editor, Plugin } from '@ckeditor/ckeditor5-core';
import { Range } from '@ckeditor/ckeditor5-engine';
import { EventInfo } from '@ckeditor/ckeditor5-utils';
import { autoParagraphEmptyContent } from '../../util/Postfixers/AutoParagraphEmptyContent';
import { removeContentBeforeSectionHeading } from '../../util/Postfixers/RemoveContentBeforeSectionHeading';
import { upcastHighlightToMarker } from './converters';
import { getMarkersInRange } from './utils';

const COMMAND_FORCE_DISABLE_ID = 'QualioLockedSectionHeaders';

export class QualioDisableSectionHeadingPlugin extends Plugin {
  commandsInLockedRegions = new Set(['selectAll', 'undo', 'redo']);

  /**
   * @inheritDoc
   */
  static get pluginName(): string {
    return 'QualioDisableSectionHeadingPlugin';
  }

  init = (): void => {
    const editor = this.editor;
    const editingView = editor.editing.view;

    this._setupConversion();
    this._setupCommandsToggling();
    this._setupRestrictions();
    editor.model.document.registerPostFixer(autoParagraphEmptyContent);
    editor.model.document.registerPostFixer(removeContentBeforeSectionHeading);
    editingView.change((writer) => {
      for (const root of editingView.document.roots) {
        writer.addClass('ck-restricted-editing_mode_restricted', root);
      }
    });
  };

  _setupConversion = (): void => {
    const editor = this.editor;

    // The restricted editing does not attach additional data to the zones so there's no need for smarter markers managing.
    // Also, the markers will only be created when loading the data.
    let markerNumber = 0;

    editor.conversion.for('upcast').add(
      upcastHighlightToMarker({
        view: {
          name: 'span',
          classes: 'locked-section-headers',
        },
        model: () => {
          markerNumber++; // Starting from lockedSectionHeaders:1 marker.

          return `lockedSectionHeaders:${markerNumber}`;
        },
      }),
    );

    editor.conversion.for('downcast').markerToHighlight({
      model: 'lockedSectionHeaders',
      view: () => {
        return {
          name: 'span',
          classes: 'locked-section-headers',
          priority: -10,
        };
      },
    });
  };

  _setupRestrictions = (): void => {
    const editor = this.editor;
    const model = editor.model;
    const viewDoc = editor.editing.view.document;
    const clipboard = editor.plugins.get('ClipboardPipeline');

    this.listenTo(model, 'deleteContent', restrictDeleteContent(editor), {
      priority: 'high',
    });

    // Block clipboard outside exception marker on paste and drop.
    this.listenTo(
      clipboard,
      'contentInsertion',
      (evt) => stopIfRestrictedArea(editor, evt),
      { priority: 'high' },
    );
    this.listenTo(
      viewDoc,
      'dragend',
      (evt) => {
        stopIfRestrictedArea(editor, evt);
      },
      { priority: 'high' },
    );
  };

  _setupCommandsToggling = (): void => {
    const editor = this.editor;
    const model = editor.model;
    const doc = model.document;
    this.listenTo(doc.selection, 'change', this._checkCommands.bind(this));
    this.listenTo(doc, 'change:data', this._checkCommands.bind(this));
  };

  _checkCommands = (): void => {
    const editor = this.editor;
    const selection = editor.model.document.selection;

    if (selection.rangeCount > 1) {
      this._enableAllCommands();
      return;
    }

    const markers = getMarkersInRange(editor, selection.getFirstRange());
    if (markers.length > 0) {
      this._disableCommands();
    } else {
      this._enableAllCommands();
    }
  };

  _disableCommands(): void {
    const editor = this.editor;
    const commands = this._getCommandNamesToToggle(editor).map((name) =>
      editor.commands.get(name),
    );
    for (const command of commands) {
      if (command) {
        command.forceDisabled(COMMAND_FORCE_DISABLE_ID);
      }
    }
  }

  _getCommandNamesToToggle(editor: Editor): Array<string> {
    return Array.from(editor.commands.names()).filter(
      (name) => !this.commandsInLockedRegions.has(name),
    );
  }

  _enableAllCommands(): void {
    const editor = this.editor;
    for (const command of editor.commands.commands()) {
      command.clearForceDisabled(COMMAND_FORCE_DISABLE_ID);
    }
  }
}

const stopIfRestrictedArea = (editor: Editor, evt: EventInfo) => {
  if (
    isRangeInsideSingleMarker(
      editor,
      editor.model.document.selection.getFirstRange(),
    )
  ) {
    evt.stop();
  }
};
const restrictDeleteContent =
  (editor: Editor) => (evt: EventInfo, args: any) => {
    const [selection] = args;

    const markers = getMarkersInRange(editor, selection.getFirstRange());

    // Stop method execution if marker was not found at selection focus.
    if (markers.length > 0) {
      evt.stop();
    }
  };

const isRangeInsideSingleMarker = (editor: Editor, range: Range | null) => {
  const markers = getMarkersInRange(editor, range);

  // Stop method execution if marker was not found at selection focus.
  return markers.length > 0;
};
