import moment from 'moment';

const DATETIME_FORMAT = 'MMM D YYYY h:mm A';
const DATE_FORMAT = 'MMM D, YYYY';

export function convertTimestampToDate(timestamp: number): Date {
  const timeInMillSeconds = timestamp * 1000;
  return new Date(timeInMillSeconds);
}

export function convertDateToDateTimeString(date: Date): string {
  // This is consistent with the date formatting in the rest of the documents space
  return moment(date).format(DATETIME_FORMAT);
}

export function convertDateToDateString(date: Date): string {
  return moment(date).format(DATE_FORMAT);
}

export function convertStringToDate(dateString: string): Date | null {
  // This works for dates from the templates backend
  if (!dateString) {
    return null;
  }
  return new Date(dateString);
}
