import { deprecated, QTag, QText } from '@qualio/ui-components';
import React, { useCallback, useMemo, useState } from 'react';

import { Template } from '../../../types';
import Loading from '../../shared/Loading/Loading';
import { getTagText } from '../../../utils/textUtil';

type TemplateTableProps = {
  data: Template[];
  isLoading: boolean;
  showTemplate: any;
};

const MAX_TITLE_TO_DISPLAY = 80;
const MAX_PREFIX_TO_DISPLAY = 28;

const TemplateTable: React.FC<TemplateTableProps> = ({ data, isLoading, showTemplate }) => {
  const [tablePageNumber, setTablePageNumber] = useState(0);
  const [tablePageSize, setTablePageSize] = useState(10);
  const [sortBy, setSortBy] = useState('prefix');
  const [sortDesc, setSortDesc] = useState(false);

  const getTagVariantColor = (key: string) => {
    switch (key) {
      case 'effective':
        return 'green';
      case 'archived':
        return 'red';
      default:
        return 'gray';
    }
  };

  const chopIfTooLong = (text: string, max_length: number): string => {
    const displayText = text || '';
    if (displayText.length > max_length) {
      return displayText.substring(0, max_length - 3) + '...';
    }
    return displayText;
  };

  const makeBadgeForPrefix = useCallback((prefix: string) => {
    return <QText data-cy={prefix}>{chopIfTooLong(prefix, MAX_PREFIX_TO_DISPLAY)}</QText>;
  }, []);

  const idTitleRowSort = useCallback(
    (templateA: Template, templateB: Template, ifId: boolean) => {
      const strA = ifId ? templateA.prefix : templateA.name;
      const strB = ifId ? templateB.prefix : templateB.name;

      return sortDesc ? strB.localeCompare(strA) : strA.localeCompare(strB);
    },
    [sortDesc],
  );

  const dateRowSort = useCallback(
    (templateA: Template, templateB: Template) => {
      const lastModifiedTimeA = (templateA.modified_time || templateA.created_time).getTime();
      const lastModifiedTimeB = (templateB.modified_time || templateB.created_time).getTime();

      return sortDesc ? lastModifiedTimeB - lastModifiedTimeA : lastModifiedTimeA - lastModifiedTimeB;
    },
    [sortDesc],
  );

  const sortedData = useMemo(() => {
    const sortRows = (templateA: Template, templateB: Template) => {
      switch (sortBy) {
        case 'prefix':
          return idTitleRowSort(templateA, templateB, true);
        case 'title':
          return idTitleRowSort(templateA, templateB, false);
        case 'version':
          return sortDesc ? templateB.version - templateA.version : templateA.version - templateB.version;
        case 'lastModified':
          return dateRowSort(templateA, templateB);
        default:
          return 0;
      }
    };

    const dataSort = [...data];
    dataSort.sort(sortRows);
    return dataSort;
  }, [data, sortBy, sortDesc, idTitleRowSort, dateRowSort]);

  const tableData = useMemo(() => {
    return sortedData
      .slice(tablePageNumber * tablePageSize, tablePageNumber * tablePageSize + tablePageSize)
      .map((template: Template) => {
        const prefix = template.prefix.replace('-', '') || '';
        return {
          id: template.id || '',
          prefixStr: prefix,
          prefix: makeBadgeForPrefix(prefix),
          title: chopIfTooLong(template.name, MAX_TITLE_TO_DISPLAY),
          status: <QTag variantColor={getTagVariantColor(template?.status || '')}>{getTagText(template.status)}</QTag>,
          version: template.version || '',
          lastModified: template.modified_time || '',
        };
      });
  }, [sortedData, tablePageNumber, tablePageSize, makeBadgeForPrefix]);

  const sortByCallback = (sortParams: any) => {
    setSortBy(sortParams[0]?.id);
    setSortDesc(!!sortParams[0]?.desc);
  };

  const paginationCallback = (pageIndex: number, pageSize: number) => {
    setTablePageNumber(pageIndex);
    setTablePageSize(pageSize);
  };

  const calculatePageAmount = () => {
    if (data.length === 0) {
      return 1;
    }

    return Math.ceil(data.length / tablePageSize);
  };

  const COLUMNS = [
    { Header: 'ID', accessor: 'prefix', width: '120px', maxWidth: '120px' },
    { Header: 'Title', accessor: 'title', width: '652px', maxWidth: '652px' },
    { Header: 'Version', accessor: 'version', width: '100px' },
    { Header: 'Last Modified', accessor: 'lastModified', width: '160px', type: deprecated.QColumnType.DATE },
    { Header: 'Status', accessor: 'status', width: '120px', disableSortBy: true },
  ];

  return isLoading ? (
    <Loading />
  ) : (
    <deprecated.QDataTable
      columns={COLUMNS}
      data={tableData || []}
      onRowClick={(row) => showTemplate(row.id)}
      manualPagination={{
        pageCount: calculatePageAmount(),
        paginationCallback: paginationCallback,
      }}
      manualSortBy={{
        sortByCallback,
        defaultSortByColumn: [{ id: 'prefix' }],
      }}
    />
  );
};

export default TemplateTable;
