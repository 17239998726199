import { medtechApiClient } from '.';
import { UpgradableDraftsPayload, UpgradeDraftResponse, UpgradeRequest } from '../types';

const draftUpgradeApi = {
  async getUpgradableDrafts(companyId: number, templateId: string): Promise<UpgradableDraftsPayload> {
    return medtechApiClient
      .get(`/v2/${companyId}/document_templates/${templateId}/upgradable_documents`)
      .then((res) => {
        return res.data as UpgradableDraftsPayload;
      })
      .catch(() => {
        // THis retries the GET if a 404 happens, this can be due to a delay in AWS DMS
        // This catch can be removed after we shut down AWS DMS for templates
        return medtechApiClient
          .get(`/v2/${companyId}/document_templates/${templateId}/upgradable_documents`)
          .then((res) => {
            return res.data as UpgradableDraftsPayload;
          });
      });
  },
  async upgradeDrafts(request: UpgradeRequest): Promise<UpgradeDraftResponse> {
    const res = await medtechApiClient.post(`v2/${request.company_id}/document_templates/upgrade_documents`, {
      target_template_id: request.target_template_id,
      documents_ids: request.documents_ids,
    });
    return res.data as UpgradeDraftResponse;
  },
};

export default draftUpgradeApi;
