import { CCSection } from '.';
import { defaultChangeControlContent } from './changeControlDefaultContent';
import { MedtechUserGroup, TemplateDetail } from './templateDetail';

export const DEFAULT_TEMPLATE_TYPE = 'text';

export class TemplateDTO {
  title: string;
  type: string;
  id: string;
  sections: SectionDTO[];
  effectiveOnApproval: string;
  periodicReviewPeriod: string;
  reviewLimit: string;
  approvalLimit: string;
  showSectionTitles: string;
  eligibleForTraining: string;
  requireRetraining: string;
  changeControl: CCSection[];
  sectionedContent: boolean;
  fileDownloadUserGroups: MedtechUserGroup[];

  constructor(templateDetail: TemplateDetail) {
    this.title = templateDetail.name || '';
    this.type = templateDetail.type || DEFAULT_TEMPLATE_TYPE;
    this.id = templateDetail.prefix || '';
    this.sections =
      templateDetail.sections.map((s) => {
        return new SectionDTO(s.id, s.title, s.position, s.content, s.type);
      }) || [];
    this.effectiveOnApproval = templateDetail.document_effective_on_approval ? 'yes' : 'no' || '';
    this.periodicReviewPeriod = templateDetail.document_review_period?.toString() || '';
    this.reviewLimit = templateDetail.document_review_due_in_days?.toString() || '';
    this.approvalLimit = templateDetail.document_approval_due_in_days?.toString() || '';
    this.showSectionTitles = templateDetail.document_text_sections_visible ? 'yes' : 'no' || '';
    this.eligibleForTraining = templateDetail.training_available ? 'yes' : 'no' || '';
    this.requireRetraining = templateDetail.training_required ? 'yes' : 'no' || '';
    this.changeControl = defaultChangeControlContent();
    this.sectionedContent = templateDetail.sectioned_content;
    this.fileDownloadUserGroups = templateDetail.file_download_user_groups ?? [];
  }
}

export class SectionDTO {
  id: number | null;
  title: string;
  position: number;
  content: string;
  type: string;

  constructor(id: number | null, title: string, position: number, content: string, type: string) {
    this.id = id;
    this.title = title;
    this.position = position;
    this.content = content;
    this.type = type;
  }
}

export type ChangeControlRequest = {
  sections: CCSection[];
};

export class SectionResolutionDTO {
  section_id: number;
  if_prepend: boolean;
  recipient_section_index: number;

  constructor(section_id: number, if_prepend: boolean, recipient_section_index: number) {
    this.section_id = section_id;
    this.if_prepend = if_prepend;
    this.recipient_section_index = recipient_section_index;
  }
}
