import { medtechApiClient } from '.';
import { CreatedChangeControl, CreatedChangeControlBackend, TemplateDetail } from '../types';

const changeControlApi = {
  async getChangeControlsForTemplate(changeControlDetail: TemplateDetail): Promise<CreatedChangeControl[]> {
    const res = await medtechApiClient.get(
      `/v2/${changeControlDetail.company_id}/changes?template_uuid=${changeControlDetail.template_uuid}`,
    );
    const createdChanges = res.data as CreatedChangeControlBackend[];
    return createdChanges.map((ccBackend) => new CreatedChangeControl(ccBackend));
  },
};

export default changeControlApi;
