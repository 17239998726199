import {
  QModal,
  QModalBody,
  QModalHeader,
  QBox,
  QHeading,
  QCloseButton,
  QText,
  QModalActions,
  QButton,
} from '@qualio/ui-components';
import React from 'react';
import { useBlocker } from 'react-router-dom';

type UnsavedChangesAlertProps = {
  showPrompt: boolean;
};

const UnsavedChangesAlert: React.FC<UnsavedChangesAlertProps> = ({ showPrompt }) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => showPrompt && currentLocation.pathname !== nextLocation.pathname,
  );
  return (
    <QModal
      isOpen={blocker.state === 'blocked'}
      onClose={() => {
        if (blocker && blocker.reset) {
          blocker.reset();
        }
      }}
    >
      <QModalBody>
        <QModalHeader>
          <QBox marginLeft={'-25px'}>
            <QHeading>Discard unsaved changes?</QHeading>
          </QBox>
          <QCloseButton
            onClick={() => {
              if (blocker && blocker.proceed) {
                blocker.proceed();
              }
            }}
          />
        </QModalHeader>
        <QText>Your changes will be lost if you don't save them.</QText>
      </QModalBody>
      <QModalActions>
        <QButton
          variant="ghost"
          onClick={() => {
            if (blocker && blocker.reset) {
              blocker.reset();
            }
          }}
        >
          Cancel
        </QButton>
        <QButton
          onClick={() => {
            if (blocker && blocker.proceed) {
              blocker.proceed();
            }
          }}
        >
          Discard
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default UnsavedChangesAlert;
