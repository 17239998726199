import { CKEditorInstance } from '../types/CKEditorInstance';

export class ToolbarManager {
  originalOffset: number | undefined;
  originalPositionStyle: string | undefined;
  originalStyleWidth: string | undefined;
  getToolbarParentElement = (): HTMLElement => {
    const toolbarParentElement = document.getElementById('pageHeaderSection');
    if (toolbarParentElement) {
      return toolbarParentElement;
    }
    throw new Error('could not locate editor toolbar target');
  };

  scrollListener = (): void => {
    const toolbarParent = this.getToolbarParentElement();
    const currentScrollOffset = document.scrollingElement?.scrollTop;
    const toolbarParentOffset = toolbarParent.getBoundingClientRect().top;
    let observed: HTMLElement | null = null;
    const observer = new ResizeObserver((entries) => {
      const parentWidth = entries[0].borderBoxSize[0].inlineSize;
      const percentage = parentWidth * 0.99;
      toolbarParent.style.width = `${percentage}px`;
    });

    if (toolbarParentOffset <= 0) {
      observed = toolbarParent.parentElement;
      observed && observer.observe(observed);
      toolbarParent.style.position = 'fixed';
      toolbarParent.style.top = '15px';
    } else if (
      currentScrollOffset &&
      this.originalOffset !== undefined &&
      currentScrollOffset <= this.originalOffset
    ) {
      this.originalPositionStyle !== undefined &&
        (toolbarParent.style.position = this.originalPositionStyle);

      this.originalStyleWidth !== undefined &&
        (toolbarParent.style.width = this.originalStyleWidth);

      if (observed) {
        observer.unobserve(observed);
        observed = null;
      }
    }
  };

  setToolbarScrollListener = (): void => {
    window.addEventListener('scroll', this.scrollListener);
  };

  setToolbarFromEditor = (editor: CKEditorInstance): void => {
    const toolbarElement = editor.ui.view.toolbar.element ?? null;
    const toolbarParentElement = this.getToolbarParentElement();
    this.originalOffset = toolbarParentElement.getBoundingClientRect().top;
    this.originalPositionStyle = toolbarParentElement.style.position;
    this.originalStyleWidth = toolbarParentElement.style.width;
    const existingToolbarElements =
      toolbarParentElement.querySelectorAll('[role="toolbar"]');
    if (toolbarElement && existingToolbarElements.length === 0) {
      this.setToolbarScrollListener();
      toolbarParentElement.insertBefore(toolbarElement, null);
    }
  };
}

export const useToolbarManager = (): ToolbarManager => new ToolbarManager();
