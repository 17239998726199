import React from 'react';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';

import TemplateListView from './views/TemplateListView/TemplateListView';
import CreateEditTemplateView from './views/CreateEditTemplateView/CreateEditTemplateView';
import { useFlags } from 'launchdarkly-react-client-sdk';
import TemplateListViewV2 from './views/TemplateListViewV2/TemplateListView';
import { QRouteGuard, ToastProvider } from '@qualio/ui-components';

const App: React.FC = () => {
  const { qualioFrontendRefresh } = useFlags();

  const enableDelegation = qualioFrontendRefresh;
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/doc-templates"
          element={
            <QRouteGuard requiredRoles={['quality']}>
              {qualioFrontendRefresh ? <TemplateListViewV2 /> : <TemplateListView />}
            </QRouteGuard>
          }
        />
        <Route
          path="/doc-templates/:templateId"
          element={
            <QRouteGuard requiredRoles={['quality']}>
              {qualioFrontendRefresh ? <TemplateListViewV2 /> : <TemplateListView />}
            </QRouteGuard>
          }
        />
        <Route
          path="/doc-templates/new"
          element={
            <QRouteGuard requiredRoles={['quality']}>
              <CreateEditTemplateView />{' '}
            </QRouteGuard>
          }
        />
        <Route
          path="/doc-templates/:templateId/edit"
          element={
            <QRouteGuard requiredRoles={['quality']}>
              <CreateEditTemplateView />
            </QRouteGuard>
          }
        />
        ,
      </>,
    ),
  );

  return (
    <ToastProvider enableDelegation={enableDelegation}>
      <div id="document-templates-container">
        <RouterProvider router={router} />
      </div>
    </ToastProvider>
  );
};

export default App;
