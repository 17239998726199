import React, { useCallback, useMemo, useState } from 'react';
import {
  createQColumnHelper,
  DataProvider,
  QBodyLayout,
  QBox,
  QDataTable,
  QEmptyState,
  QHeading,
  QSpinner,
  QStack,
  useCurrentUser,
} from '@qualio/ui-components';
import { useNavigate, useParams } from 'react-router-dom';

import { Template } from '../../types';
import templateApi from '../../services/templateApi';
import CreateTemplateButton from '../../components/TemplateListView/TemplateListViewHeader/CreateTemplateButton/CreateTemplateButton';
import { StatusFilter } from './StatusFilter';
import SlideoutPanel from '../../components/TemplateListView/SlideoutPanel/SlideoutPanel';
import { useQuery } from 'react-query';
const columnHelper = createQColumnHelper<Template>();

const TemplateListViewV2: React.FC = () => {
  const currentUser = useCurrentUser();
  const { templateId } = useParams();
  const [templateStatus, setTemplateStatus] = useState(false);
  const navigate = useNavigate();

  const loadTemplates = async () => {
    const templatesResponse = await templateApi.getTemplatesByCompanyIdAndStatusV2(
      currentUser.companyId,
      templateStatus,
    );
    return templatesResponse?.sort((a, b) => a.prefix.localeCompare(b.prefix));
  };

  const { data, isLoading } = useQuery({
    queryKey: ['templateApi', templateStatus],
    queryFn: loadTemplates,
  });

  const handleTitleAction = useCallback(
    (clickedTemplate: Template) => {
      navigate(`/doc-templates/${clickedTemplate.id}`);
    },
    [navigate],
  );

  const columns = useMemo(() => {
    return [
      columnHelper.id((template) => template.prefix, {
        header: 'ID',
        id: 'code',
        minWidth: '96px',
      }),
      columnHelper.textAction('name', handleTitleAction, {
        header: 'Title',
        id: 'title',
        minWidth: '305px',
      }),
      columnHelper.text((template) => `${template.version}`, {
        header: 'Version',
        id: 'version',
        minWidth: '200px',
      }),
      columnHelper.date('modified_time', {
        header: 'Last Modified',
        id: 'last_modified',
        minWidth: '200px',
      }),
      columnHelper.status((template) => (template.status === 'effective' ? 'Effective' : 'Archived'), {
        header: 'Status',
        id: 'status',
        minWidth: '200px',
        enableSorting: false,
        statuses: {
          Effective: 'green',
          Archived: 'red',
        },
      }),
    ];
  }, [handleTitleAction]);

  const closeSlideoutPanel = () => {
    navigate('../doc-templates', { replace: true });
  };

  if (isLoading) {
    return <QSpinner />;
  }

  return (
    <QBodyLayout.Default>
      <DataProvider.FixedWithAutoPagination data={data ?? []}>
        <QStack isInline justify="space-between">
          <QHeading size="lg" mb={6} data-cy="templates-page-heading">
            Templates
          </QHeading>
          <CreateTemplateButton />
        </QStack>
        <QStack isInline>
          <StatusFilter archivedStatus={templateStatus} setArchivedStatus={setTemplateStatus} />
        </QStack>
        <QDataTable columns={columns} data-cy="template-list-table" data-testid="template-list-table">
          <QEmptyState
            title="No Templates Found!"
            subtitle="There are no templates in this instance"
            data-cy="templates-table-empty-state"
            data-testid="templates-table-empty-state"
          />
        </QDataTable>
      </DataProvider.FixedWithAutoPagination>
      {templateId ? (
        <SlideoutPanel
          companyId={currentUser.companyId}
          templateId={templateId}
          closeSlideoutPanel={closeSlideoutPanel}
          setTemplateListLoadId={() => {
            // do nothing
          }}
        />
      ) : (
        <QBox> </QBox>
      )}
    </QBodyLayout.Default>
  );
};

export default TemplateListViewV2;
