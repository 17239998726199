import React, { useMemo, useState } from 'react';
import { QAlert, QBox, useCurrentUser } from '@qualio/ui-components';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';

import TemplateListViewHeader from '../../components/TemplateListView/TemplateListViewHeader/TemplateListViewHeader';
import TemplateTable from '../../components/TemplateListView/TemplateTable/TemplateTable';
import templateApi from '../../services/templateApi';
import SlideoutPanel from '../../components/TemplateListView/SlideoutPanel/SlideoutPanel';
import TemplateTableStatusFilter from '../../components/TemplateListView/TemplateTableStatusFilter/TemplateTableStatusFilter';
import { formErrorMessageForToast } from '../../utils/backEndErrorMessages';

const TemplateListView: React.FC = () => {
  const { templateId } = useParams();

  const { companyId } = useCurrentUser();

  const [templateStatus, setTemplateStatus] = useState('effective');

  const [templateListLoadId, setTemplateListLoadId] = useState(nanoid());

  const { data, isLoading, error } = useQuery(['templateList', companyId, templateStatus, templateListLoadId], () =>
    templateApi.getTemplatesByCompanyIdAndStatus(companyId, templateStatus),
  );

  const navigate = useNavigate();
  const closeSlideoutPanel = () => {
    navigate('../doc-templates/', { replace: true });
  };

  const templateTable = useMemo(() => {
    const showTemplate = (docTemplateId: string) => {
      navigate(`../doc-templates/${docTemplateId}`, { replace: true });
    };

    return <TemplateTable data={data || []} isLoading={isLoading} showTemplate={showTemplate} />;
  }, [data, isLoading, navigate]);

  return (
    <>
      {error && (
        <QBox mb={3}>
          <QAlert description={formErrorMessageForToast('Something went wrong', error)} status="error" />
        </QBox>
      )}
      <TemplateListViewHeader />
      <TemplateTableStatusFilter templateStatus={templateStatus} setTemplateStatus={setTemplateStatus} />
      {templateTable}
      {templateId ? (
        <SlideoutPanel
          companyId={companyId}
          templateId={templateId}
          closeSlideoutPanel={closeSlideoutPanel}
          setTemplateListLoadId={setTemplateListLoadId}
        />
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default TemplateListView;
