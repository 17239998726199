import React from 'react';
import { QAlert, QBox, QStack, useToastProvider } from '@qualio/ui-components';

import { CreatedChangeControl, Template, TemplateDetail } from '../../../../../types';
import ChangeControlVersionSummary from '../../ChangeControlVersionSummary/ChangeControlVersionSummary';
import { useQuery } from 'react-query';
import changeControlApi from '../../../../../services/changeControlApi';
import Loading from '../../../../shared/Loading/Loading';
import templateApi from '../../../../../services/templateApi';

type DesignControlTabProps = {
  templateDetail: TemplateDetail;
};

const DesignControlTab: React.FC<DesignControlTabProps> = ({ templateDetail }) => {
  const { showToast } = useToastProvider();
  const { data, isLoading, error } = useQuery([templateDetail], () => {
    const ccs = changeControlApi.getChangeControlsForTemplate(templateDetail);
    const templateVersions = templateApi.getTemplateVersionsByUuid(
      templateDetail.company_id,
      templateDetail.template_uuid,
    );
    return Promise.all([ccs, templateVersions]);
  });

  if (isLoading || data == null) {
    return (
      <QBox p={1}>
        <Loading />
      </QBox>
    );
  }

  if (error) {
    showToast({
      title: 'Error',
      description: 'Failed to retrieve template change controls',
      duration: 10,
      status: 'error',
    });
    return <></>;
  }

  const { displayItems, minVersion } = formDisplayItems(data);

  if (displayItems.length === 0) {
    return (
      <QBox mt="6">
        <QAlert status="info" description="There are no change control entries for this template"></QAlert>
      </QBox>
    );
  }

  return (
    <QBox p={1}>
      {minVersion != null && minVersion > 1 && (
        <QBox mt="6" pb="1" mb="6">
          <QAlert
            status="info"
            description={`Change control information is not available prior to version ${minVersion} of this template`}
          />
        </QBox>
      )}
      <QStack mt="6" direction={['row', 'column']} spacing="3">
        {displayItems.map((item) => (
          <ChangeControlVersionSummary changeControl={item.cc} key={item.cc.id} template={item.templateVersion} />
        ))}
      </QStack>
    </QBox>
  );
};

function formDisplayItems(data: [CreatedChangeControl[], Template[]]) {
  const displayItems = [];
  const ccs = data[0];
  const templateVersions = data[1];
  let minVersion = null;
  for (const cc of ccs) {
    const template = templateVersions.find((template) => template.id === cc.template_id);
    displayItems.push({ cc: cc, templateVersion: template });
    if (minVersion == null || (template && template?.version < minVersion)) {
      minVersion = template?.version;
    }
  }
  displayItems.sort(sortChangeControlVersions);
  return { displayItems: displayItems, minVersion: minVersion };
}

function sortChangeControlVersions(a: any, b: any) {
  // sorts with newest version on the top
  if (a.templateVersion == null) {
    return 1;
  }
  if (b.templateVersion == null) {
    return -1;
  }
  return b.templateVersion.version - a.templateVersion.version;
}

export default DesignControlTab;
