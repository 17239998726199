import { SectionDTO, SectionErrors } from '../../../types';

export const setDuplicatedTitleErrors = (sections: SectionDTO[], errors: SectionErrors[]) => {
  // Works out if titles are duplicated and sets the appropriate sectionErrors
  const checkedIndex = [];

  for (let i = 0; i < sections.length - 1; i++) {
    const currentSection = sections[i];
    let noDuplicationCurrentTitle = true;
    if (currentSection.title === '') {
      errors[i].duplicateTitle = false;
      continue;
    }

    if (checkedIndex.indexOf(i) > -1) {
      continue;
    }

    for (let j = i + 1; j < sections.length; j++) {
      if (currentSection.title === sections[j].title) {
        errors[i].duplicateTitle = true;
        errors[j].duplicateTitle = true;
        checkedIndex.push(j);
        noDuplicationCurrentTitle = false;
      }
    }

    if (noDuplicationCurrentTitle) {
      errors[i].duplicateTitle = false;
    }
  }
};
