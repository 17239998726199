import React from 'react';
import { QBox, QSpinner } from '@qualio/ui-components';

const Loading = () => {
  return (
    <QBox w="100%" textAlign="center" p={5}>
      <QSpinner role={'spinner'} />
    </QBox>
  );
};

export default Loading;
