import { QText } from '@qualio/ui-components';
import React from 'react';
import { SectionErrors } from '../../../types';

type ContentBoxErrorsProps = {
  sectionErrors: SectionErrors;
};

const ContentBoxErrors = (props: ContentBoxErrorsProps) => {
  return (
    <div>
      {props.sectionErrors.titleBlank && (
        <QText fontSize="sm" color={'red'}>
          Empty titles are not allowed
        </QText>
      )}
      {props.sectionErrors.duplicateTitle && (
        <QText fontSize="sm" color={'red'}>
          Duplicate titles are not allowed. Please use unique titles.
        </QText>
      )}
      {props.sectionErrors.invalidCharacters !== '' && (
        <QText fontSize="sm" color={'red'}>
          Invalid character(s) in title: {props.sectionErrors.invalidCharacters}
        </QText>
      )}
    </div>
  );
};

export default ContentBoxErrors;
