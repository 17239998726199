import { QIcon, QStack, QText, QBox, QButton } from '@qualio/ui-components';
import React from 'react';
import { Template } from '../../../../types';
import { CreatedChangeControl } from '../../../../types/changeControl';
import { convertDateToDateString } from '../../../../utils/dateUtils';

const goToCC = (ccId: string) => {
  window.open(`${window.location.origin}/reports/change_control/${ccId}`, '_blank');
};

type ChangeControlVersionProps = {
  changeControl: CreatedChangeControl;
  template: Template | undefined;
};

const ChangeControlVersionSummary: React.FC<ChangeControlVersionProps> = ({ changeControl, template }) => {
  return (
    <QBox border={'1px'} borderColor={'gray.200'} rounded={'md'} minHeight={'106px'} width={'200px'} p={2}>
      <QStack direction={['row']} spacing="11" justify={'space-between'}>
        <QStack direction={['column']} spacing="1">
          <QText weight="semibold">{changeControl.change_control_reference}</QText>
          <QText fontSize="xs" color="gray.500">
            Version {template?.version}
          </QText>
          <QText fontSize="xs" color="gray.500">
            {convertDateToDateString(changeControl.created_time)}
          </QText>
          <QText fontSize="xs" color="gray.500">
            {changeControl?.owner?.full_name}
          </QText>
        </QStack>
        <QBox pt={3} pr={3} justifyContent="right">
          <QButton variant="ghost" onClick={() => goToCC(changeControl.id)}>
            <QIcon iconName={'ExternalLink'} />
          </QButton>
        </QBox>
      </QStack>
    </QBox>
  );
};

export default ChangeControlVersionSummary;
