import { DateTime } from 'luxon';

export const getTagText = (status: string | undefined) => {
  switch (status) {
    case 'effective':
      return 'Effective';
    case 'archived':
      return 'Archived';
    default:
      return '';
  }
};

export const getTagColor = (key: string | undefined) => {
  switch (key) {
    case 'effective':
      return 'green';
    case 'archived':
      return 'red';
    default:
      return 'default';
  }
};

export const getDateTimeStr = (dateTime: Date | undefined) => {
  return dateTime ? DateTime.fromJSDate(dateTime).toLocaleString(DateTime.DATE_MED) || '' : '';
};

export const trimAndReplaceMultiSpaceWithOneSpace = (input: string) => {
  return input.trim().replace(/\s\s+/g, ' ');
};

export const convertToNullableNumber = (stringNumber: string | null): number | null => {
  if (stringNumber === '' || stringNumber == null) {
    return null;
  }
  return Number(stringNumber);
};
