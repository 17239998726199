import axios, { AxiosProgressEvent, CancelToken } from 'axios';
import { medtechApiClient } from '../../../../services';
import {
  Attachment,
  QualioDocument,
  QualioDocumentPatch,
  SmartlinksResolve,
  SmartlinksSuggest,
} from '../models';

export const documentApi = {
  async fetch(id: string): Promise<QualioDocument> {
    return medtechApiClient
      .get(`/documents/${id}?include_attachments=true&process_references=1`)
      .then((res) => res.data);
  },
  async update(payload: QualioDocumentPatch): Promise<void> {
    return medtechApiClient.patch(`/documents/${payload.id}`, payload);
  },
  async fetchAttachmentData(id: string): Promise<string> {
    return medtechApiClient
      .get(`/attachmentdata/${id}`)
      .then((res) => res.data);
  },
  async deleteAttachment(
    sectionId: number,
    attachmentId: number,
  ): Promise<void> {
    return medtechApiClient.delete(
      `/attachments/${attachmentId}?entity=template`,
    );
  },
  async uploadAttachment(
    file: File,
    setProgress: (progress: AxiosProgressEvent) => void,
    cancelToken: CancelToken,
    formData?: { [key: string]: string },
  ): Promise<Attachment | undefined> {
    const data = new FormData();
    data.append('file_1', file);
    if (formData) {
      for (const key in formData) {
        data.append(key, formData[key]);
      }
    }
    let httpResp: any;
    try {
      httpResp = await medtechApiClient.post(
        '/attachments?entity=template',
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: setProgress,
          cancelToken,
        },
      );
    } catch (thrown: any) {
      if (!axios.isCancel(thrown)) {
        throw thrown;
      }
    }
    return httpResp?.data;
  },
  async smartlinksSuggest(
    companyId: number,
    payload: SmartlinksSuggest,
  ): Promise<any> {
    const httpResp: any = await medtechApiClient.get(
      `/v2/${companyId}/smartlinks/suggest?search=${payload.search}`,
    );
    return httpResp.data;
  },
  async smartlinksResolve(
    companyId: number,
    payload: SmartlinksResolve,
  ): Promise<any> {
    const httpResp: any = await medtechApiClient.get(
      `/v2/${companyId}/smartlinks/resolve/${payload.code}`,
    );
    return httpResp.data;
  },
};
