import { QBox, QButton, QDivider, QStack } from '@qualio/ui-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateEditTemplatePageType } from '../../../views/CreateEditTemplateView/CreateEditTemplatePage';

export type CreateEditTemplateFooterProps = {
  page: CreateEditTemplatePageType;
  nextPage: () => void;
  onSubmit: () => void;
  previousPage: () => void;
  submitInProgress: boolean;
  changeControlEnabled: boolean;
  isLoaded: boolean;
  templateId: string | undefined;
  isFirstPage: () => boolean;
  isLastPage: () => boolean;
};

const CreateEditTemplateFooter = ({
  templateId,
  submitInProgress,
  isLoaded,
  nextPage,
  onSubmit,
  previousPage,
  isFirstPage,
  isLastPage,
}: CreateEditTemplateFooterProps) => {
  const navigate = useNavigate();

  const cancel = () => {
    if (templateId) {
      navigate(`../doc-templates/${templateId}`, { replace: true });
    } else {
      navigate('../doc-templates/', { replace: true });
    }
  };

  return (
    <>
      <QDivider orientation="horizontal" />
      <QBox pt={6}>
        <QStack direction={['column', 'row']} display="flex" justifyContent="flex-end" gap={'14px'}>
          <QButton variant="link" onClick={cancel} cy-data="cancelButton">
            Cancel
          </QButton>
          <QButton isDisabled={isFirstPage()} variant="outline" onClick={previousPage} cy-data="previousButton">
            Previous
          </QButton>
          {isLastPage() && (
            <QButton variant="solid" onClick={onSubmit} isDisabled={submitInProgress} cy-data="createButton">
              {templateId ? 'Update template' : 'Create template'}
            </QButton>
          )}
          {!isLastPage() && (
            <QButton isDisabled={!isLoaded} variant="solid" onClick={nextPage} type="button" cy-data="nextButton">
              Next
            </QButton>
          )}
        </QStack>
      </QBox>
    </>
  );
};

export default CreateEditTemplateFooter;
