import React from 'react';
import { QDrawer } from '@qualio/ui-components';

import Loading from '../../shared/Loading/Loading';
import TemplateDrawerTitle from './TemplateDrawerTitle/TemplateDrawerTitle';
import TemplateTabs from './TemplateTabs/TemplateTabs';
import OldDraftsAlert from '../../UpdateDrafts/Alert/OldDraftsAlert';
import { useTemplate } from '../../../hooks/useTemplate';

type SlideoutPanelProps = {
  companyId: number;
  templateId: string;
  closeSlideoutPanel: any;
  setTemplateListLoadId: any;
};

const SlideoutPanel: React.FC<SlideoutPanelProps> = ({
  companyId,
  templateId,
  closeSlideoutPanel,
  setTemplateListLoadId,
}) => {
  const templateQuery = useTemplate(templateId);

  if (templateQuery.isError) {
    window.location.href = `${window.location.origin}/404`;
  }

  return (
    <QDrawer
      isOpen={true}
      onClose={closeSlideoutPanel}
      size="xl"
      title={
        <TemplateDrawerTitle
          templateDetail={templateQuery.data}
          closeSlideoutPanel={closeSlideoutPanel}
          setTemplateListLoadId={setTemplateListLoadId}
        />
      }
    >
      {templateQuery.data && (
        <OldDraftsAlert companyId={companyId} templateId={templateId} version={templateQuery.data.version} />
      )}
      {templateQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <TemplateTabs templateDetail={templateQuery.data} />
        </>
      )}
    </QDrawer>
  );
};

export default SlideoutPanel;
