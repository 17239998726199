export enum CreateEditTemplatePage {
  IDAndTitle = 0,
  DefaultSections = 1,
  DefaultContentNewEditor = 2,
  PropertiesNewEditor = 3,
  ChangeControlNewEditor = 4,
}

export enum CreateEditSectionlessTemplatePage {
  IDAndTitle = 0,
  DefaultContentNewEditor = 1,
  PropertiesNewEditor = 2,
  ChangeControlNewEditor = 3,
}

export type CreateEditTemplatePageType = CreateEditTemplatePage | CreateEditSectionlessTemplatePage;
