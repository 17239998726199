import React from 'react';
import { QBox, QHeading } from '@qualio/ui-components';

import CreateTemplateButton from './CreateTemplateButton/CreateTemplateButton';

const TemplateListViewHeader = () => {
  return (
    <QBox display={'flex'} flexDirection="row" justifyContent="space-between" mb={6}>
      <QHeading size="lg">Templates</QHeading>
      <CreateTemplateButton />
    </QBox>
  );
};

export default TemplateListViewHeader;
