import React, { useEffect, useMemo, useState } from 'react';
import { useCurrentUser } from '@qualio/ui-components';
import { useParams } from 'react-router-dom';
import CreateEditTemplateHeader from '../../components/CreateEditTemplate/Header/CreateEditTemplateHeader';
import CreateEditTemplateContent from './CreateEditTemplateContent';
import CreateEditSectionlessTemplateContent from './CreateEditSectionlessTemplateContent';
import { DEFAULT_TEMPLATE_TYPE, SectionDTO, TemplateDTO } from '../../types';
import UnsavedChangesAlert from '../../components/CreateEditTemplate/UnsavedChangesAlert/UnsavedChangesAlert';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTemplate } from '../../hooks/useTemplate';

function initTemplateValues(sectionlessDocTemplate = false): TemplateDTO {
  return {
    title: '',
    type: '',
    id: '',
    sections: [new SectionDTO(null, sectionlessDocTemplate ? 'DEFAULT' : '', 1, '', DEFAULT_TEMPLATE_TYPE)],
    effectiveOnApproval: 'yes',
    periodicReviewPeriod: '',
    reviewLimit: '',
    approvalLimit: '',
    showSectionTitles: sectionlessDocTemplate ? 'no' : 'yes',
    eligibleForTraining: 'yes',
    requireRetraining: 'yes',
    changeControl: [
      { title: 'Description for change', position: 1, content: '', type: DEFAULT_TEMPLATE_TYPE },
      { title: 'Reason for change', position: 2, content: '', type: DEFAULT_TEMPLATE_TYPE },
      { title: 'Impact assessment', position: 3, content: '', type: DEFAULT_TEMPLATE_TYPE },
    ],
    sectionedContent: !sectionlessDocTemplate,
    fileDownloadUserGroups: [],
  };
}

const CreateEditTemplateView: React.FC = () => {
  const { companyId } = useCurrentUser();
  const { allowSectionlessContent } = useFlags();
  const [templateDto, setTemplateDto] = useState<TemplateDTO>(initTemplateValues(allowSectionlessContent));
  const [originalTemplateDto, setOriginalTemplateDto] = useState<TemplateDTO>(
    initTemplateValues(allowSectionlessContent),
  );
  const { templateId } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);

  const showSectionlessView = useMemo(
    () => allowSectionlessContent && (templateId ? !originalTemplateDto.sectionedContent : true),
    [allowSectionlessContent, templateId, originalTemplateDto],
  );

  if (!templateId && !isLoaded) {
    setIsLoaded(true);
  }

  if (templateId && !Number.isFinite(Number(templateId))) {
    window.location.href = `${window.location.origin}/404`;
  }

  const templateQuery = useTemplate(templateId);

  if (templateQuery.data && !isLoaded) {
    setOriginalTemplateDto(new TemplateDTO(templateQuery.data));
    setTemplateDto(new TemplateDTO(templateQuery.data));
    setIsLoaded(true);
  }

  useEffect(() => {
    if (JSON.stringify(templateDto) === JSON.stringify(originalTemplateDto)) {
      setCanShowDialogLeavingPage(false);
    } else {
      setCanShowDialogLeavingPage(true);
    }
  }, [originalTemplateDto, templateDto]);

  return (
    <>
      <CreateEditTemplateHeader templateId={templateId} />
      <UnsavedChangesAlert showPrompt={canShowDialogLeavingPage} />
      {isLoaded &&
        (showSectionlessView ? (
          <CreateEditSectionlessTemplateContent
            setCanShowDialogLeavingPage={setCanShowDialogLeavingPage}
            companyId={companyId}
            templateId={templateId}
            originalTemplateDto={originalTemplateDto}
            templateDto={templateDto}
            setTemplateDto={setTemplateDto}
            originalSectionAmount={originalTemplateDto.sections.filter((s) => !!s.id).length}
            isLoaded={isLoaded}
          />
        ) : (
          <CreateEditTemplateContent
            setCanShowDialogLeavingPage={setCanShowDialogLeavingPage}
            companyId={companyId}
            templateId={templateId}
            originalTemplateDto={originalTemplateDto}
            templateDto={templateDto}
            setTemplateDto={setTemplateDto}
            originalSectionAmount={originalTemplateDto.sections.filter((s) => !!s.id).length}
            isLoaded={isLoaded}
          />
        ))}
    </>
  );
};

export default CreateEditTemplateView;
