import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CurrentUserProvider, QualioQThemeProvider } from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';

type GlobalProviderProps = {
  currentUser: CurrentUser;
};

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ currentUser, children }) => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <CurrentUserProvider user={currentUser}>
        <QualioQThemeProvider>{children}</QualioQThemeProvider>
      </CurrentUserProvider>
    </QueryClientProvider>
  );
};
