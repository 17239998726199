import { QCloseButton, QDivider, QHeading, QStack } from '@qualio/ui-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type CreateEditTemplateHeaderProps = {
  templateId: string | undefined;
};

const CreateEditTemplateHeader = (props: CreateEditTemplateHeaderProps) => {
  const navigate = useNavigate();
  const goToTemplateListPage = () => {
    if (props.templateId) {
      navigate(`../doc-templates/${props.templateId}`, { replace: true });
    } else {
      navigate('../doc-templates/', { replace: true });
    }
  };

  return (
    <>
      <QStack direction={['column', 'row']} pb={6} display="flex" justifyContent="space-between">
        <QHeading size="lg">{props.templateId ? 'Edit Template' : 'Create Template'}</QHeading>
        <QCloseButton onClick={goToTemplateListPage} />
      </QStack>
      <QDivider color={'gray.200'} />
    </>
  );
};

export default CreateEditTemplateHeader;
