import React from 'react';
import { QTab, QTabList, QTabPanel, QTabPanels, QTabs } from '@qualio/ui-components';
import DefaultContentTab from './DefaultContentTab/DefaultContentTab';
import DesignControlTab from './DesignControlTab/DesignControlTab';
import PropertiesTab from './PropertiesTab/PropertiesTab';
import { TemplateDetail } from '../../../../types';
import { useFlags } from 'launchdarkly-react-client-sdk';

type TemplateTabsProps = {
  templateDetail: TemplateDetail | undefined;
};

const TemplateTabs: React.FC<TemplateTabsProps> = ({ templateDetail }) => {
  const { changeControlForTemplates, fileDocumentUpload } = useFlags();

  if (templateDetail == null) {
    return <></>;
  }

  return (
    <QTabs>
      <QTabList>
        <QTab>Default Content</QTab>
        <QTab>Properties</QTab>
        {changeControlForTemplates && <QTab>Change Control</QTab>}
      </QTabList>
      <QTabPanels>
        <QTabPanel p="0">
          <DefaultContentTab templateDetail={templateDetail} />
        </QTabPanel>
        <QTabPanel p="0">
          <PropertiesTab templateDetail={templateDetail} fileDocumentUploadEnabled={fileDocumentUpload} />
        </QTabPanel>
        {changeControlForTemplates && (
          <QTabPanel p="0">
            <DesignControlTab templateDetail={templateDetail} />
          </QTabPanel>
        )}
      </QTabPanels>
    </QTabs>
  );
};

export default TemplateTabs;
