import { QBox, QButton, QFormControl, QHeading, QIcon, QInput, QStack, QText } from '@qualio/ui-components';
import React, { FocusEventHandler } from 'react';
import { SectionErrors, TemplateDTO } from '../../../types';
import { trimAndReplaceMultiSpaceWithOneSpace } from '../../../utils/textUtil';
import { CreateEditTemplatePage } from '../../../views/CreateEditTemplateView/CreateEditTemplatePage';
import ContentBoxErrors from './ContentBoxErrors';

export type ContentBoxProps = {
  templateDto: TemplateDTO;
  setTemplateDto: (templateDto: TemplateDTO) => void;
  index: number;
  sectionErrors: SectionErrors[];
  setSectionErrors: (errors: SectionErrors[]) => void;
  sectionTitlesTouched: boolean[];
  setSectionTitlesTouched: (touched: boolean[]) => void;
  setPageError: (page: CreateEditTemplatePage, value: boolean) => void;
};

const INVALID_TITLE_CHARS = '\'"';

const ContentBox = (props: ContentBoxProps) => {
  const deleteSection = () => {
    const template = { ...props.templateDto };
    template.sections.splice(props.index, 1);
    for (let i = props.index; i < template.sections.length; i++) {
      template.sections[i].position--;
    }
    props.setTemplateDto(template);

    const sectionErrors = [...props.sectionErrors];
    sectionErrors.splice(props.index, 1);
    props.setSectionErrors(sectionErrors);
    checkPageError(sectionErrors);

    const sectionTitlesTouched = [...props.sectionTitlesTouched];
    sectionTitlesTouched.splice(props.index, 1);
    props.setSectionTitlesTouched(sectionTitlesTouched);
  };

  const move = (sectionPos: number, direction: number) => {
    const template = { ...props.templateDto };
    const sectionToMove = template.sections[sectionPos];
    sectionToMove.position = sectionToMove.position + direction;
    template.sections[sectionPos] = template.sections[sectionPos + direction];
    template.sections[sectionPos].position = template.sections[sectionPos].position - direction;
    template.sections[sectionPos + direction] = sectionToMove;
    props.setTemplateDto(template);

    const errors = [...props.sectionErrors];
    const errorToChange = errors[sectionPos + direction];
    errors[sectionPos + direction] = errors[sectionPos];
    errors[sectionPos] = errorToChange;
    props.setSectionErrors(errors);

    const sectionTitlesTouched = [...props.sectionTitlesTouched];
    const touchedToChange = sectionTitlesTouched[sectionPos + direction];
    sectionTitlesTouched[sectionPos + direction] = sectionTitlesTouched[sectionPos];
    sectionTitlesTouched[sectionPos] = touchedToChange;
    props.setSectionTitlesTouched(sectionTitlesTouched);
  };

  const moveUp = (sectionPos: number) => {
    move(sectionPos, -1);
  };

  const moveDown = (sectionPos: number) => {
    move(sectionPos, 1);
  };

  const checkPageError = (errors: SectionErrors[]) => {
    let pageHasError = false;

    for (const error of errors) {
      pageHasError ||= error.hasErrors();
    }

    if (!pageHasError) {
      props.setPageError(CreateEditTemplatePage.DefaultSections, false);
    }
  };

  const handleInputChange: FocusEventHandler<HTMLInputElement> = (e) => {
    const newTitle = e.target.value;
    const updatedTemplate = { ...props.templateDto };
    if (updatedTemplate.sections[props.index]) {
      updatedTemplate.sections[props.index].title = newTitle;
      props.setTemplateDto(updatedTemplate);
    }
    const updatedSectionErrors = [...props.sectionErrors];
    if (updatedSectionErrors[props.index]) {
      updatedSectionErrors[props.index].reset();
      props.setSectionErrors(updatedSectionErrors);
    }

    checkPageError(updatedSectionErrors);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const title = e.target.value;
    const updatedTemplate = { ...props.templateDto };
    const updatedSectionErrors = [...props.sectionErrors];
    if (!updatedTemplate.sections[props.index] || !updatedSectionErrors[props.index]) {
      return;
    }
    updatedTemplate.sections[props.index].title = trimAndReplaceMultiSpaceWithOneSpace(title);
    props.setTemplateDto(updatedTemplate);
    if (title.trim() === '') {
      updatedSectionErrors[props.index].titleBlank = true;
      props.setSectionErrors(updatedSectionErrors);
      props.setPageError(CreateEditTemplatePage.DefaultSections, true);
    } else {
      for (const char of INVALID_TITLE_CHARS) {
        if (title.indexOf(char) !== -1) {
          updatedSectionErrors[props.index].invalidCharacters += char;
          props.setSectionErrors(updatedSectionErrors);
          props.setPageError(CreateEditTemplatePage.DefaultSections, true);
        }
      }
    }
    if (updatedSectionErrors[props.index].duplicateTitle) {
      props.setPageError(CreateEditTemplatePage.DefaultSections, true);
    }
    const updatedSectionTitlesTouched = [...props.sectionTitlesTouched];
    if (updatedSectionTitlesTouched[props.index]) {
      updatedSectionTitlesTouched[props.index] = true;
      props.setSectionTitlesTouched(updatedSectionTitlesTouched);
    }
  };

  return (
    <QBox
      mt="6"
      border={'1px'}
      borderColor={'gray.200'}
      rounded={'md'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      overflow="hidden"
    >
      <QBox p={6}>
        <QBox pb={4}>
          <QHeading size="sm">Section {props.templateDto.sections[props.index].position}</QHeading>
        </QBox>
        <QFormControl>
          <QStack direction={['column']} spacing="4">
            <QBox>
              <QText fontSize="sm">Title</QText>
              <QInput
                onChange={handleInputChange}
                onBlur={handleBlur}
                value={props.templateDto.sections[props.index].title}
                name="title"
                isInvalid={props.sectionErrors[props.index]?.hasErrors()}
                cy-data={'title' + props.templateDto.sections[props.index].position}
              />
              {props.sectionErrors[props.index] && (
                <ContentBoxErrors sectionErrors={props.sectionErrors[props.index]} />
              )}
            </QBox>
          </QStack>
        </QFormControl>
      </QBox>
      <QBox
        border={'1px'}
        borderColor={'gray.200'}
        backgroundColor={'gray.50'}
        py={2}
        px={4}
        display={'flex'}
        justifyContent={'space-between'}
        m={'-1'}
      >
        <QButton
          variant="ghost"
          leftIcon="Trash"
          isDisabled={props.templateDto.sections.length === 1}
          onClick={deleteSection}
          cy-data={'delete' + props.templateDto.sections[props.index].position}
        >
          Delete
        </QButton>
        <QBox>
          <QButton
            variant="ghost"
            onClick={() => moveUp(props.index)}
            isDisabled={props.index === 0}
            cy-data={'moveup' + props.templateDto.sections[props.index].position}
          >
            <QIcon iconName={'ArrowUp'} />
          </QButton>
          <QButton
            variant="ghost"
            onClick={() => moveDown(props.index)}
            isDisabled={props.templateDto.sections[props.index].position === props.templateDto.sections.length}
            cy-data={'movedown' + props.templateDto.sections[props.index].position}
          >
            <QIcon iconName={'ArrowDown'} />
          </QButton>
        </QBox>
      </QBox>
    </QBox>
  );
};

export default ContentBox;
