import React from 'react';

import { QBox, QSelect, QSelectItem } from '@qualio/ui-components';
import { useSearchParams } from 'react-router-dom';

const effectiveTemplatesOption = {
  label: 'Effective',
  value: false,
};
const archivedTemplatesOption = {
  label: 'Archived',
  value: true,
};
const statusFilterOptions: QSelectItem<string, boolean>[] = [effectiveTemplatesOption, archivedTemplatesOption];

export type StatusFilterProps = {
  archivedStatus: boolean;
  setArchivedStatus: React.Dispatch<React.SetStateAction<boolean>>;
};

export const StatusFilter: React.FC<StatusFilterProps> = ({ archivedStatus, setArchivedStatus }) => {
  const [, setSearchParams] = useSearchParams();
  const onStatusFilterChange = (option: QSelectItem<string, boolean> | null) => {
    if (option !== null) {
      setArchivedStatus(option.value);
      setSearchParams((params: URLSearchParams) => {
        params.delete('pageIndex');
        return params;
      });
    }
  };

  return (
    <QBox w="30%">
      <QSelect
        size="sm"
        options={statusFilterOptions}
        onChange={onStatusFilterChange}
        value={archivedStatus}
        data-cy="template-status-filter"
        aria-label="Template status filter"
      />
    </QBox>
  );
};
