import { QBox, QFormControl, QHeading, useCurrentUser } from '@qualio/ui-components';
import React from 'react';
import { DEFAULT_TEMPLATE_TYPE, TemplateDTO } from '../../../types';
import { SectionedContentEditor } from '../../SectionedContentEditor/SectionedContentEditor';
import { PageOrientation, QualioContentType } from '../../shared/CKEditor/models';
import { PresentationUtils } from '../../shared/CKEditor/util/PresentationUtils';
import { CKEditorInstance } from '../../shared/CKEditor/types/CKEditorInstance';
import { CreateEditTemplatePageType } from '../../../views/CreateEditTemplateView/CreateEditTemplatePage';

export type ChangeControlFormProps = {
  setTemplateDto: (event: TemplateDTO) => void;
  templateDto: TemplateDTO;
  setPageError: (page: CreateEditTemplatePageType, value: boolean) => void;
  contentBlankErrors: boolean[];
  setContentBlankErrors: (errors: boolean[]) => void;
  contentTouched: boolean[];
  setContentTouched: (touched: boolean[]) => void;
};

const ChangeControlForm = (props: ChangeControlFormProps) => {
  const { companyId } = useCurrentUser();
  const changeControlSections = props.templateDto.changeControl.map((ccSection) => {
    return {
      content: ccSection.content || '',
      position: ccSection.position,
      title: ccSection.title,
      type: DEFAULT_TEMPLATE_TYPE,
      attachments: null,
      id: null,
    };
  });

  const changeControlContent = {
    sections: changeControlSections,
    content_type: QualioContentType.ChangeControl,
    orientation: PageOrientation.Portrait,
    id: null,
  };

  const handleCKEditorChange = (editor: CKEditorInstance) => {
    const editorSections = PresentationUtils.splitEditorTextIntoSections(editor.getData(), changeControlContent);
    editorSections.reverse();
    changeControlContent.sections.forEach((section) => {
      const editorSection = editorSections.pop();
      section.content = editorSection!.sectionText;
    });
    props.setTemplateDto({ ...props.templateDto, changeControl: changeControlContent.sections });
    return Promise.resolve();
  };

  return (
    <QBox className="stepper-content" pl="2" pr="2">
      <QBox pb={5}>
        <QHeading size="md">Change control</QHeading>
      </QBox>
      <QFormControl>
        <SectionedContentEditor data={changeControlContent} companyId={companyId} handleChange={handleCKEditorChange} />
      </QFormControl>
    </QBox>
  );
};

export default ChangeControlForm;
