import React from 'react';
import otherStyles from '../SectionedContentEditor/SectionedContentEditor.module.css';
import { Parser } from 'html-to-react';
import { QualioSectionedContent } from '../shared/CKEditor/models';
import { PresentationUtils } from '../shared/CKEditor/util/PresentationUtils';
import styles from './ContentViewer.module.css';

type ContentViewerProps = {
  content: QualioSectionedContent;
  isSectionedContent?: boolean;
};

const htmlToReactParser = new Parser();

const ContentViewer: React.FC<ContentViewerProps> = ({ content, isSectionedContent = true }) => {
  const processedDocumentContent = PresentationUtils.determineEditorContentFromSectionsData(content);
  const contentToParse = isSectionedContent ? processedDocumentContent : content.sections[0].content;
  const htmlContent = htmlToReactParser.parse(contentToParse);

  return (
    <div id="contentViewer" className={`${otherStyles['sections-editor__document__section-content']} document`}>
      <div className={`${styles['ck-restricted-editing_mode_restricted']} ck ck-content ck-editor__editable_inline`}>
        {htmlContent}
      </div>
    </div>
  );
};

export default ContentViewer;
